<template>
  <div class="w-full max-h-screen bg-background flex flex-col relative">
    <page-header title="Service Tickets">
      <button
        @click="showingCreateTicketPanel = true"
        class="flex items-center bg-green-200 border hover:bg-green-300 border-green-400 hover:border-green-500 hover:shadow active:shadow-inner transition duration-300 text-secondary rounded-md px-2 py-1"
      >
        <svg
          class="stroke-current h-5 w-5 mr-2"
          fill="none"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
          viewBox="0 0 24 24"
        >
          <path stroke="none" d="M0 0h24v24H0z" />
          <path
            d="M15 5v2M15 11v2M15 17v2M5 5h14a2 2 0 012 2v3a2 2 0 000 4v3a2 2 0 01-2 2H5a2 2 0 01-2-2v-3a2 2 0 000-4V7a2 2 0 012-2"
          />
        </svg>
        <span>Create Ticket</span>
      </button>
    </page-header>
    <main class="p-6 flex-1 overflow-y-scroll">
      <div class="w-full bg-white p-5 rounded shadow-md relative">
        <div class="w-full flex items-start justify-between">
          <div class="flex space-x-2">
            <button
              @click="selectTabAndClearSearch('new')"
              class="px-4 py-2 rounded transition-colors duration-300 flex items-center font-semibold"
              :class="
                currentTab == 'new'
                  ? 'bg-orange-300 text-orange-900'
                  : 'hover:bg-gray-200'
              "
            >
              <svg
                class="stroke-current h-5 w-5 mr-2"
                fill="none"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
                viewBox="0 0 24 24"
              >
                <path
                  d="M15 5v2M15 11v2M15 17v2M5 5h14a2 2 0 012 2v3a2 2 0 000 4v3a2 2 0 01-2 2H5a2 2 0 01-2-2v-3a2 2 0 000-4V7a2 2 0 012-2"
                />
              </svg>

              <span>New Tickets</span>
            </button>
            <button
              @click="selectTabAndClearSearch('review')"
              class="px-4 py-2 rounded transition-colors duration-300 flex items-center font-semibold"
              :class="
                currentTab == 'review'
                  ? 'bg-orange-300 text-orange-900'
                  : 'hover:bg-gray-200'
              "
            >
              <svg
                class="stroke-current h-5 w-5 mr-2"
                fill="none"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
                viewBox="0 0 24 24"
              >
                <path
                  d="M15 5v2M15 11v2M15 17v2M5 5h14a2 2 0 012 2v3a2 2 0 000 4v3a2 2 0 01-2 2H5a2 2 0 01-2-2v-3a2 2 0 000-4V7a2 2 0 012-2"
                />
              </svg>

              <span>Under Review</span>
            </button>
            <button
              @click="selectTabAndClearSearch('closed')"
              class="px-4 py-2 rounded transition-colors duration-300 flex items-center font-semibold"
              :class="
                currentTab == 'closed'
                  ? 'bg-orange-300 text-orange-900'
                  : 'hover:bg-gray-200'
              "
            >
              <svg
                class="stroke-current h-5 w-5 mr-2"
                fill="none"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
                viewBox="0 0 24 24"
              >
                <path
                  d="M15 5v2M15 11v2M15 17v2M5 5h14a2 2 0 012 2v3a2 2 0 000 4v3a2 2 0 01-2 2H5a2 2 0 01-2-2v-3a2 2 0 000-4V7a2 2 0 012-2"
                />
              </svg>

              <span>Closed Tickets</span>
            </button>
            <button
              @click="selectTabAndClearSearch('all')"
              class="px-4 py-2 rounded transition-colors duration-300 flex items-center font-semibold"
              :class="
                currentTab == 'all'
                  ? 'bg-orange-300 text-orange-900'
                  : 'hover:bg-gray-200'
              "
            >
              <svg
                class="stroke-current h-5 w-5 mr-2"
                fill="none"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
                viewBox="0 0 24 24"
              >
                <path
                  d="M15 5v2M15 11v2M15 17v2M5 5h14a2 2 0 012 2v3a2 2 0 000 4v3a2 2 0 01-2 2H5a2 2 0 01-2-2v-3a2 2 0 000-4V7a2 2 0 012-2"
                />
              </svg>

              <span>All Tickets</span>
            </button>

            <button
              @click="currentTab = 'search'"
              class="px-4 py-2 rounded transition-colors duration-300 flex items-center font-semibold"
              :class="
                currentTab == 'search'
                  ? 'bg-orange-300 text-orange-900'
                  : 'hover:bg-gray-200'
              "
            >
              <svg
                class="stroke-current h-5 w-5 mr-2"
                fill="none"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
                viewBox="0 0 24 24"
              >
                <circle cx="11" cy="11" r="8" />
                <path d="M21 21l-4.35-4.35" />
              </svg>
              <span>Search</span>
            </button>
          </div>
          <div class="flex justify-end flex-1">
            <input
              type="text"
              class="ml-10 w-full lg:w-1/2 rounded-l bge-input bge-input-spacing"
              placeholder="Search service tickets"
              v-model="searchTerm"
              @keydown.enter="searchTickets"
            />
            <button
              @click="searchTickets"
              class="rounded-r bg-blue-200 hover:bg-blue-300 border border-blue-400 hover:border-blue-500 p-2 hover:shadow active:shadow-inner transition-colors duration-300 ease-in-out"
            >
              <svg
                class="stroke-current h-5 w-5"
                fill="none"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
                viewBox="0 0 24 24"
              >
                <circle cx="11" cy="11" r="8" />
                <path d="M21 21l-4.35-4.35" />
              </svg>
            </button>
          </div>
        </div>
        <div v-if="viewState == 'Idle'">
          <div v-if="currentTab != 'search'">
            <vue-good-table
              class="mt-5"
              styleClass="vgt-table condensed"
              mode="remote"
              :rows="currentTabTickets"
              :columns="columns"
              :totalRows="totalTickets"
              :row-style-class="markRowAsSelected"
              @on-row-click="openServiceTicket"
              @on-page-change="onPageChange"
              @on-sort-change="onSortChange"
              @on-column-filter="onColumnFilter"
              @on-per-page-change="onPerPageChange"
              :pagination-options="{
                enabled: true,
                perPage: 50,
                perPageDropdown: [1, 2, 3, 10, 20, 50, 100],
                dropdownAllowAll: false,
              }"
            >
              <template slot="table-row" slot-scope="props">
                <span v-if="props.column.field == 'severity'">
                  <span
                    class="px-2 rounded-lg"
                    :class="{
                      'bg-red-200 text-red-700': props.row.severity == 3,
                      'bg-yellow-200 text-yellow-700': props.row.severity == 2,
                      'bg-green-200 text-green-700': props.row.severity == 1,
                      'bg-gray-300 text-gray-700': props.row.severity == 0,
                    }"
                    >{{ props.row.severity_string }}</span
                  >
                </span>
                <span v-else>{{ props.formattedRow[props.column.field] }}</span>
              </template>
            </vue-good-table>
          </div>

          <div v-if="currentTab == 'search'">
            <div v-if="searchState == 'Idle'">
              <h4 class="mt-5" v-if="searchTermWas && searchResults.length > 0">
                {{ searchResults.length }} search results for '{{
                  searchTermWas
                }}'
              </h4>
              <vue-good-table
                v-if="searchResults.length > 0"
                class="mt-5"
                styleClass="vgt-table condensed"
                mode="remote"
                :rows="searchResults"
                :columns="columns"
                :totalRows="searchResults.length"
                :row-style-class="markRowAsSelected"
                @on-row-click="openServiceTicket"
                @on-page-change="onPageChange"
                @on-sort-change="onSortChange"
                @on-column-filter="onColumnFilter"
                @on-per-page-change="onPerPageChange"
              >
                <template slot="table-row" slot-scope="props">
                  <span v-if="props.column.field == 'severity'">
                    <span
                      class="px-2 rounded-lg"
                      :class="{
                        'bg-red-200 text-red-700': props.row.severity == 3,
                        'bg-yellow-200 text-yellow-700':
                          props.row.severity == 2,
                        'bg-green-200 text-green-700': props.row.severity == 1,
                        'bg-gray-300 text-gray-700': props.row.severity == 0,
                      }"
                      >{{ props.row.severity_string }}</span
                    >
                  </span>
                  <span v-else>{{
                    props.formattedRow[props.column.field]
                  }}</span>
                </template>
              </vue-good-table>
              <h5
                class="text-center py-3"
                v-if="searchTermWas && searchResults.length == 0"
              >
                No results for {{ searchTermWas }}
              </h5>
            </div>
            <div
              v-if="searchState == 'Searching'"
              class="flex items-center justify-center py-20"
            >
              <Spinner :color="'text-black'" :size="10" />
            </div>
            <div v-if="searchState == 'Error'">
              <div class="mt-5 p-6 rounded bg-red-300">
                Something went wrong performing your search, please try again.
                If the problem persists, please contact your support team.
              </div>
            </div>
          </div>
        </div>
        <div
          v-if="viewState == 'Loading'"
          class="flex items-center justify-center py-20"
        >
          <spinner :color="'text-black'" :size="10" />
        </div>
      </div>
    </main>
    <portal to="overlay-outlet">
      <Panel
        :showing="showingCreateTicketPanel"
        @close="showingCreateTicketPanel = false"
        title="Create Service Ticket"
      >
        <CreateServiceTicket @complete="ticketCreated" />
      </Panel>
    </portal>
  </div>
</template>

<script>
const PageHeader = () => import("@/components/PageHeader.vue");
const Alert = () => import("@/components/Alert.vue");
const Notification = () => import("@/components/Notification.vue");
const Spinner = () => import("@/components/Spinner.vue");
const Toggle = () => import("@/components/Toggle.vue");
const CreateServiceTicket = () =>
  import("@/components/Dashboard/ServiceTickets/CreateServiceTicket.vue");
const Panel = () => import("@/components/Panel.vue");

export default {
  name: "ServiceTickets",
  components: {
    PageHeader,
    Alert,
    Spinner,
    Toggle,
    CreateServiceTicket,
    Panel,
  },
  data() {
    return {
      viewState: "Idle",
      searchState: "Idle",
      currentTab: "new",
      searchTerm: "",
      searchTermWas: "",
      searchResults: [],
      showingCreateTicketPanel: false,
      serviceTickets: [],
      totalTickets: 0,
      totalPages: 0,
      currentPage: 0,
      pagingParams: {
        page: 1,
        limit: 50,
      },
      columns: [
        {
          label: "TICKET #",
          field: "ticket_number",
          width: "150px",
          tdClass: this.markTableData,
        },
        {
          label: "Severity",
          field: "severity",
          width: "150px",
          tdClass: this.markTableData,
        },
        {
          label: "Title",
          field: "title",
          tdClass: this.markTableData,
        },
        {
          label: "Customer",
          field: "contact_name",
          tdClass: this.markTableData,
        },
        {
          label: "Equipment",
          field: "equipment_name",
          tdClass: this.markTableData,
        },
        {
          label: "Follow Up At",
          field: "follow_up_at",
          width: "200px",
          type: "date",
          formatFn: (v) => {
            return v == 0 ? "" : this.$moment.unix(v).format("DD/MM/YYYY");
          },
          tdClass: this.markTableData,
        },
      ],
    };
  },
  computed: {
    searchOptions: function() {
      return this.$store.state.serviceTicketOptions;
    },
    currentTabTickets: function() {
      switch (this.currentTab) {
        case "new":
          return this.serviceTickets.filter((t) => t.is_new);

        case "review":
          return this.serviceTickets.filter((t) => !t.is_new && !t.is_closed);

        case "closed":
          return this.serviceTickets.filter((t) => t.is_closed);

        case "all":
          return this.serviceTickets;
      }
    },
  },
  async mounted() {
    try {
      this.viewState = "Loading";
      await this.loadTickets();

      this.searchTerm = this.$store.state.ticketSearchTerm;
      await this.searchTickets();
      this.viewState = "Idle";
    } catch (error) {
      console.error(error);
      this.viewState = "Error";
      this.error = error;
    }
  },
  methods: {
    selectTabAndClearSearch: function(tabName) {
      this.$nextTick(() => {
        this.currentTab = tabName;
      });
      this.$store.dispatch("setTicketSearchTerm", "");
    },
    async searchTickets() {
      if (!this.searchTerm || this.searchTerm.length == 0) return;

      this.$store.dispatch("setTicketSearchTerm", this.searchTerm);

      try {
        this.hasSearchResults = true;
        this.searchState = "Searching";

        this.$nextTick(() => {
          this.currentTab = "search";
        });
        let results = await this.ServiceTicketService.getServiceTickets(
          this.pagingParams,
          true,
          "",
          this.searchTerm
        );

        this.searchResults = results.data.map((tik) => {
          let strSeverity = tik.severity;
          switch (tik.severity) {
            case 0:
              strSeverity = "Normal";
              break;
            case 1:
              strSeverity = "Low";
              break;
            case 2:
              strSeverity = "Medium";
              break;
            case 3:
              strSeverity = "High";
              break;
          }

          return {
            severity_string: strSeverity,
            ...tik,
          };
        });

        this.searchTermWas = this.searchTerm;
        this.searchState = "Idle";
      } catch (err) {
        console.error(err);
        this.searchState = "Error";
      }
    },
    ticketCreated: async function() {
      await this.loadTickets();
      this.showingCreateTicketPanel = false;
    },
    loadTickets: async function() {
      this.viewState = "Loading";
      try {
        let result = await this.ServiceTicketService.getServiceTickets(
          this.pagingParams,
          true
        );
        this.serviceTickets = result.data.map((tik) => {
          let strSeverity = tik.severity;
          switch (tik.severity) {
            case 0:
              strSeverity = "Normal";
              break;
            case 1:
              strSeverity = "Low";
              break;
            case 2:
              strSeverity = "Medium";
              break;
            case 3:
              strSeverity = "High";
              break;
          }

          return {
            severity_string: strSeverity,
            ...tik,
          };
        });
        this.totalTickets = result.count;
      } catch (error) {
        console.error(error);
      } finally {
        this.viewState = "Idle";
      }
    },
    updateSearchOptions: async function(prop, value) {
      let options = this.searchOptions;
      options[prop] = value;

      this.$store.dispatch("setServiceTicketOptions", options);
      if (this.currentTab == "search") {
        await this.searchTickets();
      } else {
        await this.loadTickets();
      }
    },
    markRowAsSelected: function(row) {
      return (this.currentTab == "all" || this.currentTab == "search") &&
        row.is_closed
        ? "bg-red-200"
        : "";
    },
    markTableData: function(row) {
      return this.currentTab == "search" && row.is_closed
        ? "vgt-text-override"
        : "text-vgt-default";
      // if (row.field > 50) {
      //   return "red-class";
      // }
      // return "green-class";
    },
    async onPageChange(params) {
      this.updatePagingParams({ page: params.currentPage });
      if (this.currentTab == "search") {
        await this.searchTickets();
      } else {
        await this.loadTickets();
      }
    },

    async onPerPageChange(params) {
      this.updatePagingParams({ limit: params.currentPerPage });
      if (this.currentTab == "search") {
        await this.searchTickets();
      } else {
        await this.loadTickets();
      }
    },

    async onSortChange(params) {
      this.updatePagingParams({
        order: params[0].type,
        orderBy: params[0].field,
      });
      if (this.currentTab == "search") {
        await this.searchTickets();
      } else {
        await this.loadTickets();
      }
    },

    updatePagingParams(newProps) {
      console.log("updateParams", newProps);
      this.pagingParams = Object.assign({}, this.pagingParams, newProps);
    },

    onColumnFilter(params) {
      console.log("onColumnFilter", params);
    },
    openServiceTicket: function(selectedRow) {
      let ticketId = selectedRow.row.ticket_id;
      this.$router.push({
        name: "service-ticket-edit",
        params: { ticketId: ticketId },
      });
    },
  },
};
</script>
